import React from 'react'
import style from "./sideSection.module.css"
import { IoCloseCircle } from 'react-icons/io5'

const SideSection = ({ isAppear, onClose, children }) => {
    return (
        <div className={style.container} style={{ transform: isAppear ? `translateX(0)` : `translateX(100%)`, backgroundColor: isAppear ? "rgba(0, 0, 0, 0.5)" : "rgba(0, 0, 0, 0)" }}>
            <div className={style.contentContainer} style={{ transform: isAppear ? `translateX(0)` : `translateX(100%)` }}>
                <div className={style.header}>
                    <h2>Card Title</h2>
                    <button onClick={onClose}><IoCloseCircle /></button>
                </div>
                <div className={style.content}>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default SideSection