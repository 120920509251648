import React, { useEffect, useState } from 'react'
import style from "./blogCard.module.css"
import img from "../../assets/images/img25.jpg"
import { Link } from 'react-router-dom'
import EditBlog from './actions/editBlog';
import DeleteBlog from './actions/deleteBlog';


const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });

    return formattedDate;
};

const BlogCard = ({ id, title, date, languages, refetch }) => {

    return (
        <div className={style.cardContainer}>
            <div className={style.container}>
                {/* <img src={img} alt="" /> */}
                <h2>{title}</h2>
                <ul className={style.data}>
                    <li>{formatDate(date)}</li>
                    <li>No Comments</li>
                </ul>
                <div className={style.languages}>
                    {!languages.length ? <p>No Languages</p>
                        :
                        <ul>{
                            languages.map((lang) => <li>{lang.language}</li>)
                        }</ul>
                    }
                </div>
                {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Blanditiis, sequi praesentium! Ipsam quisquam explicabo, aperiam non at possimus in eum dolore similique deserunt tempora, quam vel doloremque nisi modi aut.</p> */}
            </div>
            <div className={style.actionSection}>
                <EditBlog id={id} title={title} refetch={refetch} />
                <DeleteBlog title={title} id={id} refetch={refetch} />
            </div>
            <Link to={`/admin/blog/${id}`} state={{ title }} className={`${style.showMore} button-secondary`}>Show More</Link>
        </div>
    )
}

export default BlogCard
