import React from "react";
import { children } from "react";
import { Link } from "react-router-dom";


const formatDate = (dateString) => {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  return formattedDate;
};

const ArchiveCard = ({ id,imgSrc, title, date, comments, children }) => {
  return (
    <>
      <article class="post">
        <figure class="feature-image">
          <a href="#">
            <img src={imgSrc} alt="" />
          </a>
        </figure>
        <div class="entry-content">
          <h3>
            <Link to={`/blog/${id}`}>{title}</Link>
          </h3>
          <div class="entry-meta">
            <span class="posted-on">
              <a href="blog-single.html">{formatDate(date)}</a>
            </span>
            <span class="comments-link">
              <a href="blog-single.html">{comments}</a>
            </span>
          </div>
          {/* <p>{children}...</p> */}
          {/* <a href="blog-single.html" class="button-text">
            ...
          </a> */}
        </div>
      </article>
    </>
  );
};

export default ArchiveCard;
