import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { api_url } from '../../../../constants/base_url';
import { notifyError, notifySuccess } from '../../../../App';
import Modal from '../../../../components/shared/Modal/Modal';
import Form from '../../../../components/shared/Form/Form';
import { BsPencilFill } from 'react-icons/bs';
import style from "../blogCard.module.css"

const EditBlog = ({ id, title, refetch }) => {
    const [editBox, setEditBox] = useState(false);

    const {
        register,
        setValue,
        formState: { errors },
        handleSubmit,
    } = useForm();

    useEffect(() => {
        setValue("title", title);
    }, [title, setValue]);

    const { mutate: editBlog, isError, error, isLoading } = useMutation({
        mutationFn: (data) => {
            return axios.post(`${api_url}blog/${id}`, data, {
                headers: {
                    "Accept": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                },
            });
        },
        onSuccess: (data) => {
            console.log("Blog edited successfully", data);
            setEditBox(false);
            notifySuccess(`${title} Blog Edited Successfully`);
            refetch();
        },
        onError: (error) => {
            console.error("Edit Blog failed:", error);
            notifyError(error?.response?.data?.message);
        }
    });

    const submit = async (data) => {
        console.log(data);
        const formData = new FormData();
        formData.append("title", data.title);
        formData.append("_method", "PUT");

        await editBlog(formData); // Await the mutation call
    };

    return (
        <>
            <span className='transitionAnimation' onClick={() => setEditBox(true)}><BsPencilFill /> Edit</span>

            <Modal isAppear={editBox} onClose={() => setEditBox(false)}>
                <Form className={style.form} onSubmit={handleSubmit(submit)}>
                    <h2 style={{ textAlign: "center" }}>{`Edit ${title}`}</h2>

                    <Form.TextController
                        register={register}
                        registername="title"
                        placeholder={"Blog Name"}
                        errorMessage={errors?.title && errors?.title?.message}
                        validationRules={{
                            required: 'Blog name is required',
                        }}
                    />

                    <div className={style.subDiv}>
                        <Form.ButtonController type='submit' isLoading={isLoading} main>Edit</Form.ButtonController>
                        <Form.ButtonController onClick={() => setEditBox(false)} sub>Cancel</Form.ButtonController>
                    </div>
                </Form>
            </Modal>
        </>
    )
}

export default EditBlog