import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import style from "./dashboardBlogPage.module.css";
import img from "../../assets/images/img25.jpg"
import { IoCloseCircle } from "react-icons/io5";
import Modal from '../../../components/shared/Modal/Modal';
import Form from '../../../components/shared/Form/Form';
import axios from 'axios';
import { api_url } from '../../../constants/base_url';
import { notifyError, notifySuccess } from '../../../App';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FaPlus } from "react-icons/fa6";
import { useForm } from 'react-hook-form';
import Loading from '../../../components/shared/Loading/Loading';
import BlogSubCards from './blogSubCards/blogSubCards';
import EditLang from './lang/editLang';
import DeleteLang from './lang/deleteLang';
import Empty from '../../../components/empty/empty';
import AddLang from './lang/addLang';
import GetAllLangs from './lang/getAllLangs';

const DashboardBlogPage = () => {
    const { blogId } = useParams();
    const location = useLocation();
    const { title } = location.state || {};

    const [cardLang, setCardLang] = useState({});


    const { data, error, isLoading, isError, refetch } = useQuery({
        queryKey: ['blogCard'],
        queryFn: () =>
            axios.get(`${api_url}blog/adminShow/${blogId}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                },
            }),
        onSuccess: (response) => {
            console.log("Data fetched successfully:", response.data);
        },
        onError: (err) => {
            console.error("Error fetching data:", err);
        }
    });


    console.log("blog card data:", data?.data?.data)

    console.log("cardLang", cardLang);


    return (
        <main className='mainContainer'>
            {cardLang ? <>
                <GetAllLangs blogId={blogId} setCardLang={setCardLang} title={title} />
            </>
                :
                <div className={style.empty}>
                    <Empty text={"Empty Blog"} />
                    <AddLang blogID={blogId} refetch={refetch} inEmpty />
                </div>
            }
        </main>
    )
}

export default DashboardBlogPage






