import React from "react";
import img5 from "../../images/img5.jpg";
import { Link } from "react-router-dom";
import { api_url } from "../../constants/base_url";
function PackageCard({
  buttonColor,
  children,
  recommended,
  people,
  description,
  title,
  image,
  duration,
  reviews,
  rating,
  location,
}) {
  return (
    <>
      <div className="col-lg-3 col-md-4 col-sm-6">
        <div className="package-wrap">
          <figure className="feature-image">
            <Link to="https://wa.me/+201033973047">
              <img
                src={`${api_url}${image}`.replace("/api/", "/")}
                alt={title}
                height={200}
                style={{ height: 350, objectFit: "cover" }}
              />
            </Link>
          </figure>
          {recommended && (
            <div className="featured text-white p-2 ">recommended for you</div>
          )}
          <div className="package-content-wrap">
            <div className="package-meta text-center">
              <ul>
                <li>
                  <i className="far fa-clock"></i>
                  {duration}
                </li>
                <li>
                  <i className="fas fa-user-friends"></i>
                  People: {people}
                </li>
                <li>
                  <i className="fas fa-map-marker-alt"></i>
                  {location}
                </li>
              </ul>
            </div>
            <div className="package-content">
              <h3>
                <Link to="https://wa.me/+201033973047">{title}</Link>
              </h3>
              <div className="review-area">
                <span className="review-text">({reviews})</span>
                <div className="rating-start" title="Rated 5 out of 5">
                  <span style={{ width: reviews + "%" }}></span>
                </div>
              </div>
              <p>{description && description.slice(0, 50) + " ..."}</p>
              <div className="btn-wrap">
                <Link
                  to="https://wa.me/+201033973047"
                  className={`button-text ${
                    buttonColor === "red" ? "text-white book" : ""
                  } width-6`}
                >
                  Book Now<i className="fas fa-arrow-right"></i>
                </Link>

                <h6 className="package-price">{children}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PackageCard;
