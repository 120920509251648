// // SliderItem.js
// import React from 'react';

// function SliderItem({ backgroundImage, title }) {
//     return (
//         <div className="home-banner-items">
//             <div className="banner-inner-wrap" style={{ backgroundImage: `url(${backgroundImage})` }}></div>
//             <div className="banner-content-wrap">
//                 <div className="container-fluid">
//                     <div className="banner-content text-center py-5">
//                         <h2 className="banner-title">{title}</h2>
//                         {/* Home search field html start */}
//                         <div className="trip-search-section">
//                             {/* <div className="slider-shape"></div> */}
//                             <div className="container-fluid">
//                                 <div className="trip-search-inner bg-white d-flex justify-content-center">
//                                     <div className="input-group col-md-5">
//                                         <input type="text" name="s" placeholder="Enter Destination" />
//                                     </div>
//                                     <div className="input-group col-md-5">
//                                         <input className="input-date-picker" type="date" name="s" placeholder="when?" />
//                                     </div>

//                                     <div className="input-group col-md-2">
//                                         <input type="submit" name="travel-search" value="INQUIRE" />
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                         {/* search search field html end */}
//                         <a href="#" className="button-primary">CONTINUE READING</a>
//                     </div>
//                 </div>
//             </div>
//             <div className="overlay"></div>
//         </div>
//     );
// }

// export default SliderItem;

import React from "react";
import EnterDestination from "./../EnterDestination";

export default function SliderItem({ backgroundImage, title }) {
  return (
    <div>
      <div className="home-banner-items">
        <div
          className="banner-inner-wrap"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        ></div>
        <div className="banner-content-wrap">
          <div className="container-fluid">
            <div className="banner-content text-center py-5">
              <h2 className="banner-title">{title}</h2>
              {/* Home search field html start */}
              <div className="trip-search-section ">
                {/* <div className="slider-shape"></div> */}
                <div className="container-fluid ">
                  <div className="trip-search-inner  d-flex justify-content-center">
                    <div className="input-group col-md-5">
                      {/* <input type="text" name="s" placeholder="Enter Destination" /> */}
                      {/* <EnterDestination /> */}
                      <select name="travel-destination">
                        <option value="cairo">Cairo</option>
                        <option value="giza">Giza</option>
                        <option value="luxor">Luxor</option>
                        <option value="hurghada">Hurghada</option>
                      </select>
                      {/* <EnterDestination /> */}
                    </div>
                    <div className="input-group col-md-5">
                      <input
                        className="input-date-picker"
                        type="date"
                        name="s"
                        placeholder="when?"
                      />
                    </div>

                    <div className="input-group col-md-2">
                      <input
                        type="submit"
                        name="travel-search"
                        value="INQUIRE"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="overlay"></div>
      </div>
    </div>
  );
}
