import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { api_url } from '../../../constants/base_url';
import axios from 'axios';
import style from "./dashboardBlogs.module.css"
import Loading from '../../../components/shared/Loading/Loading';
import Pagination from '../../components/pagination/pagination';
import EmptyNoItems from '../../components/emptyNoItems/emptyNoItems';
import Modal from '../../../components/shared/Modal/Modal';
import Form from '../../../components/shared/Form/Form';
import { useForm } from 'react-hook-form';
import { notifyError, notifySuccess } from '../../../App';
import BlogCard from '../../components/blogCard/blogCard';
import { useRecoilState } from 'recoil';
import blogsState from '../../atoms/blogs-atom';
import AddBlog from './add/AddBlog';

const DashboardBlogs = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [allBlogs, setAllBlogs] = useRecoilState(blogsState);

    const { data, error, isLoading, isError, refetch } = useQuery({
        queryKey: ['blog', currentPage],
        queryFn: () =>
            axios.get(`${api_url}blog?page=${currentPage}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                },
            }),
        onSuccess: (response) => {
            console.log("Data fetched successfully:", response.data);
        },
        onError: (err) => {
            console.error("Error fetching data:", err);
        }
    });

    const totalPages = data?.data?.last_page || 1;
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    useEffect(() => {
        if (data?.data?.data?.data) {
            setAllBlogs((prevBlogs) => [...prevBlogs, ...data.data.data.data]);
        }
    }, [data]);

    console.log("Blog Data:", data?.data?.data?.data)

    return (
        <main className='mainContainer'>
            <div className={style.addTour}>
                <AddBlog refetch={refetch} />
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />
            </div>

            {isLoading ?
                <div className={style.loading}>
                    <Loading />
                </div>
                :
                data?.data?.data?.data?.length === 0 ?
                    <EmptyNoItems text={"No Blogs Yet!"} />
                    :
                    <div className={style.packagesContainer}>
                        {data?.data?.data?.data?.map((blog) => (
                            <BlogCard
                                id={blog._id}
                                title={blog.title}
                                date={blog.created_at}
                                languages={detectLanguages(blog)}
                                refetch={refetch}
                            />
                        ))}
                    </div>
            }
            <Outlet />
        </main>
    )
}

export default DashboardBlogs


const detectLanguages = (blog) => {
    const nonLanguageKeys = ['_id', 'title', 'created_at', 'updated_at'];

    const keys = Object.keys(blog);

    const languageKeys = keys.filter(key => !nonLanguageKeys.includes(key));

    const languages = languageKeys.map(key => ({ language: key, id: blog[key] }));

    return languages;
};