import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { api_url } from "../constants/base_url";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import bg from "../images/bg.jpg";
import logo from "../images/logo.png";
import AuthForm from '../components/AuthForm/AuthForm';
import { signupSchema } from '../Validations/auth/signup.schema';
import Modal from '../components/shared/Modal/Modal';
import { notifyError, notifySuccess } from '../App';


const Signup = () => {
  const [verifyEmailBox, setVerifyEmailBox] = useState(false);
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');

  const schema = signupSchema();

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: zodResolver(schema),
  });

  const { mutate: registerUser, isError, error, isLoading } = useMutation({
    mutationFn: (data) => {
      return axios.post(`${api_url}auth/register`, data, {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
      });
    },
    onSuccess: (data) => {
      console.log("Registration successful", data);
      setVerifyEmailBox(true);
    },
    onError: (error) => {
      console.error("Registration failed:", error);
      notifyError(error?.response?.data?.message);
    }
  });

  const { mutate: verifyCode, isLoading: isVerifying } = useMutation({
    mutationFn: (verificationData) => {
      return axios.post(`${api_url}auth/verifyEmail`, verificationData, {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
      });
    },
    onSuccess: () => {
      console.log("Email verification successful");
      notifySuccess("Email verification successful")
      setVerifyEmailBox(false);
    },
    onError: (error) => {
      console.error("Verification failed:", error);
      notifyError(error?.response?.data?.message);
    }
  });

  const onSubmit = (data) => {
    delete data.confirmPassword;
    data.role = 'user';
    console.log("Form Data:", data);
    setEmail(data.email);
    registerUser(data);
  };

  const handleVerificationSubmit = () => {
    const verificationData = {
      email: email,
      verification_code: verificationCode
    };
    verifyCode(verificationData);
    console.log("verificationData", verificationData)

  };

  return (
    <div className="login-page" style={{ backgroundImage: `url(${bg})` }}>
      <div className="login-from-wrap">
        <AuthForm onSubmit={handleSubmit(onSubmit)}>
          <h1 className="site-title">
            <Link to="#">
              <img src={logo} alt="logo" />
            </Link>
          </h1>

          <AuthForm.TextController
            id="username"
            label="Username"
            errors={errors}
            register={register}
            name='name'
          />

          <AuthForm.TextController
            id="email"
            label="Email"
            errors={errors}
            register={register}
            name='email'
          />

          <AuthForm.TextController
            id="phone"
            label="Phone"
            errors={errors}
            register={register}
            name='phone'
          />

          <AuthForm.TextController
            id="password"
            label="Password"
            type='password'
            errors={errors}
            register={register}
            name='password'
          />

          <AuthForm.TextController
            id="c-password"
            label="Confirm Password"
            type='password'
            errors={errors}
            register={register}
            name='confirmPassword'
          />

          <AuthForm.ButtonController isLoading={isLoading} type='submit'>Register</AuthForm.ButtonController>

          <p className="swap-login-signup">
            Already have an account? <Link to="/login">Login</Link>.
          </p>
        </AuthForm>
      </div>
      {verifyEmailBox &&
        <Modal isAppear onClose={() => setVerifyEmailBox(false)}>
          <div className="verify-email-box">
            <h2>Verify Your Email</h2>
            <p>We have sent a verification email to <strong>{email}</strong>.</p>
            <p>Please check your inbox and enter code to verify your email.</p>
            <input
              type="text"
              placeholder='Verfication Code'
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
            />
            <button className="button-primary" onClick={handleVerificationSubmit} style={{backgroundColor: isVerifying ? "gray" : ""}} disabled={isVerifying}>
              {isVerifying ? 'Verifying...' : 'Submit Code'}
            </button>
          </div>
        </Modal>
      }
    </div>
  )
}

export default Signup