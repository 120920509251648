import React, { useEffect, useState } from 'react'
import style from "./blogSubCards.module.css"
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { api_url } from '../../../../constants/base_url'
import { notifyError, notifySuccess } from '../../../../App'
import Modal from '../../../../components/shared/Modal/Modal'
import Form from '../../../../components/shared/Form/Form'
import Loading from '../../../../components/shared/Loading/Loading'
import { COLORS } from '../../../../constants/colors'
import { BsPencilFill, BsTrashFill } from 'react-icons/bs'

const BlogSubCards = ({ blogId, blogLanguageId, subCards, refetch, isLoading }) => {

    console.log(`langID: ${blogLanguageId}`)
    console.log(`subCards`, subCards)


    return (
        <div className={style.container}>
            <div className={style.addButton}>
                <AddCard blogLanguageId={blogLanguageId} refetch={refetch} />
            </div>
            {isLoading ?
                <Loading color={COLORS.MAIN_COLOR.MAIN} />
                :
                <div className={style.cardsContainer}>
                    {subCards.map((item, index) =>
                        <div className={style.card}>
                            <img src={`${api_url}${item.image[0]}`.replace("/api/", "/storage/")} alt="" />
                            <h3>{item.title}</h3>
                            <p>{item.content}</p>
                            <div className={style.actionSection}>
                                <EditCard blogLanguageId={blogLanguageId} index={index + 1} cardData={item} refetch={refetch} />
                                <DeleteCard blogLanguageId={blogLanguageId} index={index + 1} title={item.title} refetch={refetch} />
                            </div>
                            <button className='button-secondary' style={{ width: "100%", marginTop: "10px" }}>Show Images</button>
                        </div>
                    )}

                </div>
            }
            {/* <Modal isAppear={openBox} onClose={() => setOpenBox(false)}>
                <img src={item.image} alt="" />
            </Modal> */}
        </div>
    )
}

export default BlogSubCards


const AddCard = ({ blogLanguageId, refetch }) => {
    const [openAddCardBox, setOpenAddCardBox] = useState(false);

    const {
        register,
        setValue,
        formState: { errors },
        handleSubmit,
    } = useForm();

    const { mutate: addBlog, isError, error, isLoading } = useMutation({
        mutationFn: (data) => {
            return axios.post(`${api_url}blogLanguage/subCard`, data, {
                headers: {
                    "Accept": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                },
            });
        },
        onSuccess: (data) => {
            console.log("Blog add successful", data);
            setOpenAddCardBox(false);
            notifySuccess("Blog Successfully Added");
            refetch();
        },
        onError: (error) => {
            console.error("add blog failed:", error);
            notifyError(error?.response?.data?.message);
        }
    });


    function submit(data) {
        console.log(data)
        const formData = new FormData();
        formData.append("blogLanguageId", blogLanguageId);
        formData.append("title", data.title);
        formData.append("content", data.content);

        let x = [data.image, data.image]
        // formData.append("image", x);
        x.forEach((image) => {
            formData.append("images[]", image);
        });

        addBlog(formData)
    }


    return (
        <>
            <button className='button-secondary' onClick={() => setOpenAddCardBox(true)}>Add Card</button>

            <Modal isAppear={openAddCardBox} onClose={() => setOpenAddCardBox(false)}>
                <Form
                    title={"Add Card"}
                    onSubmit={handleSubmit(submit)}
                    className={style.form}
                >
                    <Form.TextController
                        register={register}
                        registername="title"
                        placeholder={"title"}
                        errorMessage={errors?.title && errors?.title?.message}
                        validationRules={{
                            required: 'title is required',
                        }}
                    />
                    <Form.TextareaController
                        register={register}
                        registername="content"
                        placeholder={"content"}
                        errorMessage={errors?.content && errors?.content?.message}
                        validationRules={{
                            required: 'content is required',
                        }}
                    />
                    <Form.DragAndDropController
                        register={register}
                        registername="image"
                        setValue={setValue}
                        errors={errors}
                    />
                    <Form.ButtonController type='submit' isLoading={isLoading}>Add</Form.ButtonController>
                </Form>
            </Modal>
        </>
    )
}


const DeleteCard = ({ blogLanguageId, index, title, refetch }) => {
    const [deleteBox, setDeleteBox] = useState(false)
    const { mutate: deleteCard, isError, error, isLoading } = useMutation({
        mutationFn: () => {
            return axios.post(`${api_url}blogLanguage/deleteSubCard`, { _method: "DELETE", blogLanguageId: blogLanguageId, index: index }, {
                headers: {
                    "Accept": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                },
            });
        },
        onSuccess: (data) => {
            console.log("Blog Deleted successful", data);
            setDeleteBox(false);
            notifySuccess(`${title} Blog Deleted Successfully`);
            refetch();
        },
        onError: (error) => {
            console.error("Delete Blog failed:", error);
            notifyError(error?.response?.data?.message);
        }
    });

    return (
        <>
            <span className='transitionAnimation' onClick={() => setDeleteBox(true)}><BsTrashFill /> Delete</span>
            <Modal isAppear={deleteBox} onClose={() => setDeleteBox(false)}>
                <Form className={style.form}>
                    <h2 style={{ textAlign: "center" }}>{`Delete ${title} ?`}</h2>
                    <div className={style.subDiv}>
                        <Form.ButtonController onClick={deleteCard} isLoading={isLoading} main red>Delete</Form.ButtonController>
                        <Form.ButtonController onClick={() => setDeleteBox(false)} sub red>Cancel</Form.ButtonController>
                    </div>
                </Form>
            </Modal>
        </>
    )
}


const EditCard = ({ blogLanguageId, index, cardData, refetch }) => {
    const [editBox, setEditBox] = useState(false);

    const {
        register,
        setValue,
        formState: { errors },
        handleSubmit,
    } = useForm();

    useEffect(() => {
        setValue("title", cardData.title);
        // setValue("image", cardData.image);
        setValue("content", cardData.content);
    }, [cardData.title, cardData.content, setValue]);

    const { mutate: editBlog, isError, error, isLoading } = useMutation({
        mutationFn: (data) => {
            return axios.post(`${api_url}blogLanguage/updateSubCard`, data, {
                headers: {
                    "Accept": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                },
            });
        },
        onSuccess: (data) => {
            console.log("Blog edited successfully", data);
            setEditBox(false);
            notifySuccess(`${cardData.title} Blog Edited Successfully`);
            refetch();
        },
        onError: (error) => {
            console.error("Edit Blog failed:", error);
            notifyError(error?.response?.data?.message);
        }
    });

    const urlToFile = async (url) => {
        const response = await fetch(url);
        const blob = await response.blob();
        const filename = url.split('/').pop(); // Extract filename from URL
        return new File([blob], filename, { type: blob.type });
    };

    const submit = async (data) => {
        console.log(data);
        const formData = new FormData();
        formData.append("blogLanguageId", blogLanguageId);
        formData.append("index", index);
        formData.append("title", data.title);
        formData.append("content", data.content);
        formData.append("_method", "PUT");

        // if (data.image instanceof File) {
        //     formData.append("image", data.image);
        // } else if (typeof data.image === "string") {
        //     const imageFile = await urlToFile(data.image); // Await the file conversion
        //     formData.append("image", imageFile);
        // }

        await editBlog(formData); // Await the mutation call
    };

    return (
        <>
            <span className='transitionAnimation' onClick={() => setEditBox(true)}><BsPencilFill /> Edit</span>

            <Modal isAppear={editBox} onClose={() => setEditBox(false)}>
                <Form className={style.form} onSubmit={handleSubmit(submit)}>
                    <h2 style={{ textAlign: "center" }}>{`Edit ${cardData.title}`}</h2>

                    <Form.TextController
                        register={register}
                        registername="title"
                        placeholder={"Card title"}
                        errorMessage={errors?.title && errors?.title?.message}
                        validationRules={{
                            required: 'Card title is required',
                        }}
                    />
                    <Form.TextController
                        register={register}
                        registername="content"
                        placeholder={"Card content"}
                        errorMessage={errors?.content && errors?.content?.message}
                        validationRules={{
                            required: 'Card content is required',
                        }}
                    />
                    {/* <Form.DragAndDropController
                        initialValue={cardData.image}
                        register={register}
                        registername="image"
                        setValue={setValue}
                        errors={errors}
                    /> */}

                    <div className={style.subDiv}>
                        <Form.ButtonController type='submit' isLoading={isLoading} main>Edit</Form.ButtonController>
                        <Form.ButtonController onClick={() => setEditBox(false)} sub>Cancel</Form.ButtonController>
                    </div>
                </Form>
            </Modal>
        </>
    )
}
