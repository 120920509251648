import React from "react";
import ReactDom from "react-dom";

import style from "./Modal.module.css";
import { IoCloseCircle } from "react-icons/io5";

const BackDrop = ({ closeModal }) => {
  return <div className={style.backdrop} onClick={closeModal}></div>;
};

const Overlay = ({ body, closeModal, styles }) => {
  return (
    <div className={style.overlay} style={styles}>
      <div className={style.closeButton}>
        <IoCloseCircle onClick={closeModal} />
      </div>
      {body}
    </div>
  );
};

const Modal = ({ children, isAppear, onClose, styles }) => {
  return (
    isAppear && (
      <>
        {ReactDom.createPortal(
          <>
            <BackDrop closeModal={onClose} />
            <Overlay
              styles={{
                ...styles,
                overflow: "auto",
                maxHeight: "100vh",
                maxWidth: "90vw",
              }}
              closeModal={onClose}
              body={children}
            />
          </>,

          document.getElementById("modal")
        )}
      </>
    )
  );
};

export default Modal;
