import React, { useState } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import Banner from "../components/shared/banner";
import innerBanner from "../images/inner-banner.jpg";
import ArchiveCard from "../components/archiveCard";
import img17 from "../images/camel-trip.jpg";
import img18 from "../images/img18.jpg";
import img19 from "../images/img19.jpg";
import img35 from "../images/img35.jpg";
import img21 from "../images/img21.jpg";
import img34 from "../images/img34.jpg";
import img36 from "../images/img36.jpg";
import PostThumb from "../components/postThumb";
import WidgetSocial from "../components/widgetSocial";
import SocialMadia from "../components/socialMadia";
import { useQuery } from "@tanstack/react-query";
import { api_url } from "../constants/base_url";
import axios from "axios";
import Pagination from "../Admin/components/pagination/pagination";
import Loading from "../components/shared/Loading/Loading";
import { Outlet } from "react-router-dom";

const Blog = () => {
  const ArchiveData = [
    {
      id: 1,
      title: `The Wonders of Egypt
Egypt, the land of ancient pharaohs and timeless treasures, awaits you! Dive into the rich history, stunning landscapes, and vibrant culture of this magnificent country with Album Travel`,
      imgSrc: img17,
      date: "May 23, 2024 11:31 Am",
      comments: "No comments",
    },
    {
      id: 2,
      title: `We believe that travel is more than just a getaway—it’s a gateway to a richer, fuller life. Here are some incredible benefits of traveling that you can experience with us`,
      imgSrc: img18,
      date: "May 23, 2024 11:31 Am",
      comments: "No comments",
    },
    {
      id: 3,
      title: `You cannot visit the land of the Pharaohs without having a taste of its delectable mouthwatering food, right?`,
      imgSrc: img19,
      date: "April 6, 2022 10:20 Am",
      comments: "No comments",
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);


  const { data, error, isLoading, isError, refetch } = useQuery({
    queryKey: ['gestBlog', currentPage],
    queryFn: () =>
      axios.get(`${api_url}blog?page=${currentPage}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
    onSuccess: (response) => {
      console.log("Data fetched successfully:", response.data);
    },
    onError: (err) => {
      console.error("Error fetching data:", err);
    }
  });

  const totalPages = data?.data?.last_page || 1;
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  console.log("Blog Data:", data?.data?.data?.data)

  return (
    <>
      <main id="content" class="site-main">
        <Banner innerBanner={innerBanner}>Archives</Banner>
        <div class="archive-section blog-archive">
          <div class="archive-inner">
            <div class="container">
              <div class="row">
                <div class="col-lg-8 primary right-sidebar">
                  {isLoading ?
                    <div className={"loading"}>
                      <Loading />
                    </div>
                    :
                    <>
                      <div
                        class="grid row"
                        style={{ position: "relative", height: "1551.11px" }}
                      >
                        {data?.data?.data?.data?.map((blog) => (
                          <div class="grid-item col-md-6">
                            <ArchiveCard
                              id={blog._id}
                              title={blog.title}
                              // imgSrc={i.imgSrc}
                              date={blog.created_at}
                              comments={"No Comments"}
                            >
                              {/* Praesent, risus adipisicing donec! Cras. Lobortis id
                              aliquip taciti repudiandae porro dolore facere
                              officia! Natoque mollitia ultrices convallis nisl
                              suscipit */}
                            </ArchiveCard>
                          </div>
                        ))}
                      </div>

                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                      />
                    </>}
                </div>

                <div class="col-lg-4 secondary">
                  <div class="sidebar">
                    <aside class="widget author_widget">
                      <h3 class="widget-title">ABOUT AUTHOR</h3>
                      <div class="widget-content text-center">
                        <div class="profile">
                          <figure class="avatar">
                            <a href="#">
                              <img src={img21} alt="" />
                            </a>
                          </figure>
                          <div class="text-content">
                            <div class="name-title">
                              <h3>
                                <a href="https://demo.bosathemes.com/bosa/photography/james-watson/">
                                  Album Travel
                                </a>
                              </h3>
                            </div>
                            <p>
                              We are committed to curate and flawlessly execute
                              travel experiences that allow travelers to explore
                              the world with ease and create memories that last
                              a lifetime.
                            </p>
                          </div>
                          <SocialMadia />
                        </div>
                      </div>
                    </aside>
                    <aside class="widget widget_latest_post widget-post-thumb">
                      <h3 class="widget-title">Recent Post</h3>
                      <ul>
                        {ArchiveData.map((i) => (
                          <PostThumb
                            imgSrc={i.imgSrc}
                            title={i.title}
                            date={i.date}
                            comments={i.comments}
                          />
                        ))}
                      </ul>
                    </aside>
                    <aside class="widget widget_social">
                      <h3 class="widget-title">Social share</h3>
                      <div class="social-icon-wrap">

                        <WidgetSocial
                          type="facebook"
                          name="Facebook"
                          href={"https://www.facebook.com/albumtravelegypt?mibextid=ZbWKwL"} >
                          <i class="icon fab fa-facebook-f"></i>
                        </WidgetSocial>

                        <WidgetSocial
                          type="pinterest"
                          name="Pinterest"
                          href={"https://www.pinterest.com/Albumtravel/"}
                        >
                          <i class="icon fab fa-pinterest"></i>
                        </WidgetSocial>

                        <WidgetSocial
                          type="whatsapp"
                          name="Whatsapp"
                          href={"https://wa.me/+201033973047"}>
                          <i class="icon fab fa-whatsapp"></i>
                        </WidgetSocial>

                        <WidgetSocial
                          type="tiktok"
                          name="TikTok"
                          href={"https://wa.me/+201033973047"}>
                          <svg className="icon fab fa-tiktok" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-tiktok" viewBox="0 0 16 18">
                            <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z" />
                          </svg>
                        </WidgetSocial>

                        <WidgetSocial
                          type="instagram"
                          name="Instagram"
                          href={"https://www.instagram.com/albumtravelegypt?igsh=MWtmamk2aGRpMTVqYg=="}>
                          <i className="icon fab fa-instagram" aria-hidden="true"></i>
                        </WidgetSocial>

                        <WidgetSocial
                          type="tripadvisor"
                          name="Tripadvisor"
                          href={"https://www.tripadvisor.com/Profile/albumtravel"}>
                          <i class="icon fab fa-tripadvisor" aria-hidden="true"></i>
                        </WidgetSocial>

                        <WidgetSocial
                          type="trustpilot"
                          name="Trustpilot"
                          href={"https://www.trustpilot.com/review/albumtravel.com"}>
                          <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="20px" height="20px" viewBox="0 0 25 25" role="img" fill="#ffffff"><title>Trustpilot icon</title><path d="M12,17.964l5.214-1.321l2.179,6.714L12,17.964z M24,9.286h-9.179L12,0.643L9.179,9.286 H0l7.429,5.357l-2.821,8.643l7.429-5.357l4.571-3.286L24,9.286L24,9.286L24,9.286L24,9.286z" /></svg>

                        </WidgetSocial>

                      </div>
                    </aside>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Outlet />
      </main >
    </>
  );
};

export default Blog;
