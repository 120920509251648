import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { api_url } from '../../../../constants/base_url';
import Loading from '../../../../components/shared/Loading/Loading';
import { notifyError, notifySuccess } from '../../../../App';
import { IoCloseCircle } from 'react-icons/io5';
import Modal from '../../../../components/shared/Modal/Modal';
import Form from '../../../../components/shared/Form/Form';
import style from "../dashboardBlogPage.module.css"
import SideSection from '../../../../components/sideSection/sideSection';
import BlogSubCards from '../blogSubCards/blogSubCards';
import Empty from '../../../../components/empty/empty';
import AddLang from './addLang';

const GetAllLangs = ({ blogId, title }) => {
    const [currentBlogLanguages, setCurrentBlogLanguages] = useState([]);
    const { data, error, isLoading, isError, refetch } = useQuery({
        queryKey: ['blogCard'],
        queryFn: () =>
            axios.get(`${api_url}blogLanguage`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                },
            }),
        onSuccess: (response) => {
            console.log("All Languages:", response.data);
        },
        onError: (err) => {
            console.error("Error fetching data:", err);
        }
    });

    // console.log("get all langs:", data?.data?.data)
    // console.log("get current blog lang:", data?.data?.data.filter(item => item.blogId === blogId))

    useEffect(() => {
        if (isLoading) {
            console.log("loading")
        }
        else {
            // setCardLang(data?.data?.data.find(item => item.blogId === blogId))
            setCurrentBlogLanguages(data?.data?.data?.filter(item => item.blogId === blogId))
        }
    }, [data, isLoading, blogId])

    console.log("dataLanguagesssss:", data)
    console.log("currentBlogLanguagesssss:", currentBlogLanguages)



    return (
        isLoading ?
            <div className={style.loading}>
                <Loading />
            </div>
            :
            currentBlogLanguages.length ?
                <>
                    <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}><AddLang blogID={blogId} refetch={refetch} inEmpty /></div>
                    <div className={style.langCardsContainer}>
                        {
                            currentBlogLanguages.map((lang, index) =>
                                <LangCard id={lang._id} index={index} refetch={refetch} lang={lang} title={title}>{lang.language}</LangCard>
                            )
                        }
                    </div>
                </>
                :
                <div className={style.empty}>
                    <Empty text={"There is no Blog languages"} />
                    <AddLang blogID={blogId} refetch={refetch} inEmpty />
                </div>
    )
}

export default GetAllLangs

const LangCard = ({ id, refetch, lang, title }) => {
    const [openDeleteBox, setOpenDeleteBox] = useState(false);
    const [showSubCards, setShowSubCards] = useState(false);

    const { mutate: deleteLang, isError, error, isLoading } = useMutation({
        mutationFn: () => {
            return axios.delete(`${api_url}blogLanguage/${id}`, {
                headers: {
                    "Accept": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                },
            });
        },
        onSuccess: (data) => {
            console.log("Language Deleted successful", data);
            setOpenDeleteBox(false);
            notifySuccess(`${lang.language} Language Deleted Successfully`);
            refetch();
        },
        onError: (error) => {
            console.error("Delete lang failed:", error);
            notifyError(error?.response?.data?.message);
        }
    });


    const subCards = Object.keys(lang)
        .filter(key => key.startsWith('sub_card_'))
        .map(key => lang[key]);

    console.log("maincardinblog:", lang)

    return (
        <>
            <div className={style.langCard}>
                <div className={style.header}>
                    <p>{lang.language}</p>
                    <IoCloseCircle onClick={() => setOpenDeleteBox(true)} />
                </div>
                {/* <img src="" alt="" /> */}
                <h2>{title}</h2>
                <p>{lang.main_card.content}</p>
                <button className='button-secondary' onClick={() => setShowSubCards(true)}>Show Cards</button>
            </div>
            <Modal isAppear={openDeleteBox} onClose={() => setOpenDeleteBox(false)}>
                <Form className={style.form}>
                    <h2 style={{ textAlign: "center" }}>{`Delete ${lang.language} Language ?`}</h2>
                    <div className={style.subDiv}>
                        <Form.ButtonController onClick={deleteLang} isLoading={isLoading} main red>Delete</Form.ButtonController>
                        <Form.ButtonController onClick={() => setOpenDeleteBox(false)} sub red>Cancel</Form.ButtonController>
                    </div>
                </Form>
            </Modal>
            <SideSection isAppear={showSubCards} onClose={() => setShowSubCards(false)}>
                <BlogSubCards blogId={lang.blogId} blogLanguageId={lang._id} subCards={subCards} refetch={refetch} isLoading={isLoading} />
            </SideSection>
        </>
    )
}

