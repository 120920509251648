import React, { useEffect, useState } from 'react'
import "../../dashboardIndex.css"
import destinationStyle from "./destinationCard.module.css"
import { BsPencilFill } from 'react-icons/bs';
import { BsTrashFill } from 'react-icons/bs';
import { notifyError, notifySuccess } from '../../../App';
import axios from 'axios';
import { api_url } from '../../../constants/base_url';
import { useMutation } from '@tanstack/react-query';
import Modal from '../../../components/shared/Modal/Modal';
import Form from '../../../components/shared/Form/Form';
import { useForm } from 'react-hook-form';

const DestinationCard = ({ id, image, country, content, rating, refetch }) => {

    return (
        <div className={destinationStyle.card}>
            <div className={destinationStyle.imageSection} style={{ backgroundImage: `url(${image})` }}>
                <div className={destinationStyle.contentSection}>
                    <div>
                        <h3>{country}</h3>
                        <div className={destinationStyle.ratingStart} title="Rated 5 out of 5">
                            <span style={{ width: rating }}></span>
                        </div>
                        <p className='transitionAnimation'>{content}</p>
                    </div>
                </div>
            </div>
            <div className={destinationStyle.actionSection}>
                <EditDestination id={id} country={country} image={image} refetch={refetch} />
                <DeleteDestination country={country} id={id} refetch={refetch} />
            </div>
        </div>
    )
}

export default DestinationCard


const DeleteDestination = ({ id, country, refetch }) => {
    const [deleteBox, setDeleteBox] = useState(false)
    const { mutate: deleteDestination, isError, error, isLoading } = useMutation({
        mutationFn: () => {
            return axios.post(`${api_url}destinations/${id}`, { _method: "DELETE" }, {
                headers: {
                    "Accept": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                },
            });
        },
        onSuccess: (data) => {
            console.log("destination Deleted successful", data);
            setDeleteBox(false);
            notifySuccess(`${country} Destination Deleted Successfully`);
            refetch();
        },
        onError: (error) => {
            console.error("Delete destination failed:", error);
            notifyError(error?.response?.data?.message);
        }
    });

    return (
        <>
            <span className='transitionAnimation' onClick={() => setDeleteBox(true)}><BsTrashFill /> Delete</span>
            <Modal isAppear={deleteBox} onClose={() => setDeleteBox(false)}>
                <Form className={destinationStyle.form}>
                    <h2 style={{ textAlign: "center" }}>{`Delete ${country} ?`}</h2>
                    <div className={destinationStyle.subDiv}>
                        <Form.ButtonController onClick={deleteDestination} isLoading={isLoading} main red>Delete</Form.ButtonController>
                        <Form.ButtonController onClick={() => setDeleteBox(false)} sub red>Cancel</Form.ButtonController>
                    </div>
                </Form>
            </Modal>
        </>
    )
}



const EditDestination = ({ id, country, image, refetch }) => {
    const [editBox, setEditBox] = useState(false);

    const {
        register,
        setValue,
        formState: { errors },
        handleSubmit,
    } = useForm();

    useEffect(() => {
        setValue("name", country);
        setValue("image", image);
    }, [country, image, setValue]);

    const { mutate: editDestination, isError, error, isLoading } = useMutation({
        mutationFn: (data) => {
            return axios.post(`${api_url}destinations/${id}`, data, {
                headers: {
                    "Accept": "application/json",
                    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                },
            });
        },
        onSuccess: (data) => {
            console.log("Destination edited successfully", data);
            setEditBox(false);
            notifySuccess(`${country} Destination Edited Successfully`);
            refetch();
        },
        onError: (error) => {
            console.error("Edit destination failed:", error);
            notifyError(error?.response?.data?.message);
        }
    });

    const urlToFile = async (url) => {
        const response = await fetch(url);
        const blob = await response.blob();
        const filename = url.split('/').pop(); // Extract filename from URL
        return new File([blob], filename, { type: blob.type });
    };

    const submit = async (data) => {
        console.log(data);
        const formData = new FormData();
        formData.append("name", data.name);
        formData.append("_method", "PUT");

        if (data.image instanceof File) {
            formData.append("image", data.image);
        } else if (typeof data.image === "string") {
            const imageFile = await urlToFile(data.image); // Await the file conversion
            formData.append("image", imageFile);
        }

        await editDestination(formData); // Await the mutation call
    };

    return (
        <>
            <span className='transitionAnimation' onClick={() => setEditBox(true)}><BsPencilFill /> Edit</span>

            <Modal isAppear={editBox} onClose={() => setEditBox(false)}>
                <Form className={destinationStyle.form} onSubmit={handleSubmit(submit)}>
                    <h2 style={{ textAlign: "center" }}>{`Edit ${country}`}</h2>

                    <Form.TextController
                        register={register}
                        registername="name"
                        placeholder={"Destination Name"}
                        errorMessage={errors?.name && errors?.name?.message}
                        validationRules={{
                            required: 'Destination name is required',
                        }}
                    />
                    <Form.DragAndDropController
                        initialValue={image}
                        register={register}
                        registername="image"
                        setValue={setValue}
                        errors={errors}
                    />

                    <div className={destinationStyle.subDiv}>
                        <Form.ButtonController type='submit' isLoading={isLoading} main>Edit</Form.ButtonController>
                        <Form.ButtonController onClick={() => setEditBox(false)} sub>Cancel</Form.ButtonController>
                    </div>
                </Form>
            </Modal>
        </>
    )
}
