import React from "react";
import SubscribeSection from "../../components/subscribeSection";
import DistiItem from "../../components/distiItem";

import img1 from "../../images/jordan.jpg";
import img2 from "../../images/palestine.jfif";
import img3 from "../../images/khalifa-dubai.jfif";
import img4 from "../../images/img4.jpg";
import IneerBanner from "../../components/IneerBanner";
import { useQuery } from "@tanstack/react-query";
import { api_url } from "../../constants/base_url";
import style from "./destination.module.css"
import axios from "axios";

const Destination = () => {

  const { data, error, isLoading, isError, refetch } = useQuery({
    queryKey: ['destinations'],
    queryFn: () =>
      axios.get(`${api_url}destinations`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }),
    onSuccess: (response) => {
      console.log("Data fetched successfully:", response.data);
    },
    onError: (err) => {
      console.error("Error fetching data:", err);
    }
  });

  return (
    <>
      <main id="content" class="site-main">
        <IneerBanner bannerName={"Destination"} />

        <section className={style.destinationsContainer}>
          {data?.data?.data?.map((destination) => (
            <DistiItem
              imgSrc={`${api_url}${destination.image}`.replace("/api/", "/storage/")}
              country={destination.name}
              content="jordan Capital"
            />
          ))}
        </section>

        {/* <section class="destination-section destination-page">
          <div class="container">
            <div class="destination-inner destination-three-column">
              <div class="destination-inner destination-three-column">
                <div class="row">
                  <div class="col-lg-7">
                    <div class="row">
                      <div class="col-sm-6">
                        <DistiItem
                          imgSrc={img1}
                          country="Jordan"
                          content="jordan Capital"
                        />
                      </div>
                      <div class="col-sm-6">
                        <DistiItem
                          imgSrc={img2}
                          country="palestine"
                          content="Al-Aqsa Mosque"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-5">
                    <div class="row">
                      <div class="col-md-6 col-xl-12">
                        <DistiItem
                          imgSrc={img3}
                          country="Dubai"
                          content="khalifa tower"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-5">
                    <div class="row">
                      <div class="col-md-6 col-xl-12">
                        <DistiItem
                          imgSrc={img3}
                          country="NEW ZEALAND"
                          content="Oxolotan City"
                        />
                      </div>
                      <div class="col-md-6 col-xl-12">
                        <DistiItem
                          imgSrc={img4}
                          country="SINGAPORE"
                          content="Marina Bay Sand City"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-7">
                    <div class="row">
                      <div class="col-sm-6">
                        <DistiItem
                          imgSrc={img1}
                          country="THAILAND"
                          content="Disney Land"
                        />
                      </div>
                      <div class="col-sm-6">
                        <DistiItem
                          imgSrc={img2}
                          country="NORWAY"
                          content="Besseggen Ridge"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <SubscribeSection />
      </main>
    </>
  );
};

export default Destination;
